<template>
  <b-overlay :show="loading" rounded="sm">
    <b-card title="Reporte de depositos">
      <div class="row mb-1">
        <div class="col display flex">
          <date-picker
            range-separator="-"
            v-model="rangePicker"
            :default-value="rangePicker"
            range
            append-to-body
            lang="es"
            format="YYYY-MM-DD"
            style="width: 200px"
            class="mr-1"
          ></date-picker>
          <b-button
            variant="success"
            class="btn-icon mr-1"
            @click="getReportDeposits"
          >
            <feather-icon icon="RefreshCwIcon" size="16"/>
          </b-button>
        </div>
      </div>

      <b>
        * El reporte se lista en base a las ordenes que se encuentren en el intervalo de fechas seleccionado
        (por defecto el mes actual)
      </b>

      <hr>

      <b-table
        :items="report"
        :fields="tableColumns"
        striped
        responsive
      >
        <template #cell(show_details)="row">
          <b-form-checkbox
            v-model="row.detailsShowing"
            plain
            class="custom-control-primary"
            @change="row.toggleDetails"
          >
            <span class="vs-label">{{ row.detailsShowing ? 'Ocultar' : 'Detalle' }}</span>
          </b-form-checkbox>
        </template>

        <template #row-details="row">
          <b-card :title="`Monto total de depositos por orden a ${row.item.distributor.full_name}`">
            <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
            >
              <b-card :style="`height: ${row.item.orders.length > 0 ? '150px' : '0'}`">
                <b-list-group flush>
                  <b-list-group-item v-for="(order, i) of row.item.orders" :key="i">
                    <router-link :to="{ name: 'order-detail', params: { id: order.id } }">
                      Orden # {{ order.code }}
                    </router-link>
                    <b class="ml-5">
                      Monto depositado: {{ currencyFormat(order.total_deposit_for_distributor) }}
                    </b>
                    <b class="ml-5">
                      Monto de compras: {{ currencyFormat(order.additional_total) }}
                    </b>
                  </b-list-group-item>
                </b-list-group>
              </b-card>

            </vue-perfect-scrollbar>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Ocultar
            </b-button>
          </b-card>
        </template>

        <template #cell(distributor)="data">
          {{ data.item.distributor.full_name }} <br>
          <b-badge
            :variant="`${data.item.distributor.active ? 'success' : 'danger'}`"
            class="badge-glow"
          >
            {{ data.item.distributor.active ? 'Activo' : 'Inactivo' }}
          </b-badge>
        </template>

        <template #cell(total_deposit)="data">
          <b>{{ currencyFormat(data.item.total_deposit) }}</b>
        </template>

        <template #cell(total_addons)="data">
          <b>{{ currencyFormat(data.item.total_addons) }}</b>
        </template>

        <template #cell(orders)="data">
          <b>{{ data.item.orders.length }} {{ data.item.orders.length > 1 ? 'Ordenes' : 'Orden' }}</b>
        </template>
      </b-table>

      <p class="text-center" v-if="!report.length">
        No hay depositos realizados en este rango de fechas
      </p>

    </b-card>
  </b-overlay>
</template>

<script>
import ReportService from '@/services/report.service'
import DatePicker from 'vue2-datepicker';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import {
  currencyFormat
} from '@/helpers'

import {
  BOverlay,
  BCard,
  BButton,
  BTable,
  BBadge,
  BFormCheckbox,
  BListGroup,
  BListGroupItem
} from 'bootstrap-vue'

export default {
  components: {
    VuePerfectScrollbar,
    BOverlay,
    BCard,
    DatePicker,
    BButton,
    BTable,
    BBadge,
    BFormCheckbox,
    BListGroup,
    BListGroupItem
  },
  data() {
    return {
      perfectScrollbarSettings: {
        maxScrollbarLength: 10,
      },
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
				new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      ],
      report: [],
      loading: false,
      tableColumns: [
        { key: 'show_details', label: 'Ver detalle' },
        { key: 'distributor', label: 'Repartidor' },
        { key: 'orders', label: 'Cantidad de ordenes' },
        { key: 'total_deposit', label: 'Total depositado' },
        { key: 'total_addons', label: 'Total en compras' }
      ]
    }
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getReportDeposits()
      }
    }
  },
  methods: {
    async getReportDeposits() {
      try {
        this.loading = true

        const { data:res } = await ReportService.getReportDeposits(this.rangePicker)

        this.report = res.data

        this.loading = false

      } catch (error) {
        console.log(error)
        this.loading = false
      }
    },
    currencyFormat(amount) {
      return currencyFormat(amount)
    }
  },
  created() {
    this.getReportDeposits()
  }
}
</script>
